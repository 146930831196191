export const BG_COVER = `https://ik.imagekit.io/adelinejune/firhanalya/COVER%20POTRAIT.jpg?updatedAt=1697094478893`;
export const BG_COUNTING = `https://ik.imagekit.io/adelinejune/firhanalya/counting%20days.jpg?updatedAt=1697172384489`;
export const BG_FOOTER = `https://ik.imagekit.io/adelinejune/firhanalya/footer%20potrait.jpg?updatedAt=1697172366178`;
export const BG_LIVESTREAM = ``;
export const BG_LOVESTORY = `https://ik.imagekit.io/adelinejune/firhanalya/Foto%20Love%20Story.jpg?updatedAt=1697094891000`;
export const BG_GIFTCARD = ``;
export const BG_DESKTOP_VIEW = `https://ik.imagekit.io/adelinejune/firhanalya/desktop%20landscape.jpg?updatedAt=1697094247768`;

export const IMG_LAMPION = `https://ik.imagekit.io/adelinejune/firhanalya/Asset/icon%20chand%20opsi%201(3)_Fpyljjcou.png?updatedAt=1697173220381`;
// images welcome section
export const IMG_WELCOME_1 = `https://ik.imagekit.io/adelinejune/firhanalya/welcoming%20landscape.jpg?updatedAt=1697095516345`;
export const IMG_WELCOME_2 = `https://ik.imagekit.io/adelinejune/firhanalya/welcoming%20landscape%202.jpg?updatedAt=1697095516315`;
export const IMG_WELCOME_3 = `https://ik.imagekit.io/adelinejune/firhanalya/welcoming%20landscape%203.jpg?updatedAt=1697095515923`;
export const IMG_WELCOME_4 = `https://ik.imagekit.io/adelinejune/firhanalya/welcoming%20landscape%204.jpg?updatedAt=1697095516540`;
export const IMG_WELCOME_5 = `https://user-images.githubusercontent.com/10141928/150647845-8df7fa24-7b06-4d0d-869d-dd78fc8db98d.jpeg`;
// couple image
export const IMG_MAN = `https://ik.imagekit.io/adelinejune/firhanalya/groom%20potrait.jpg?updatedAt=1697446932177`;
export const IMG_GIRL = `https://ik.imagekit.io/adelinejune/firhanalya/bride%20potrait.jpg?updatedAt=1697295521732`;
export const IMG_PHOTO_COVER = `https://ik.imagekit.io/adelinejune/firhanalya/Asset/pattern%20opsi%202.jpg?updatedAt=1697172884819`;
export const IMG_LOGO_WEDDING = `https://ik.imagekit.io/adelinejune/firhanalya/Asset/castle%20opsi%203.PNG?updatedAt=1697172971355`;
export const IMG_LOVE_STORY = `https://user-images.githubusercontent.com/10141928/149857534-17748784-0c8a-451a-80e0-941e2798927f.jpg`;
export const IMG_LOGO = ``;

// video assets
export const VIDEO_COVER = `https://ik.imagekit.io/adelinejune/firhanalya/video.mp4?updatedAt=1697099397990`;
// image photo gallery cover
export const IMG_PHOTO_1 = `https://ik.imagekit.io/adelinejune/firhanalya/cover%20gallery.jpg?updatedAt=1697095087542`;
export const IMG_PHOTO_2 = `https://ik.imagekit.io/adelinejune/firhanalya/cover%20gallery%20potrait%202.jpg?updatedAt=1697095084009`;
export const IMG_PHOTO_3 = `https://ik.imagekit.io/adelinejune/firhanalya/cover%20gallery%20potrait%203.jpg?updatedAt=1697095086044`;
// image icons
export const IMG_ICON_HAND = `https://user-images.githubusercontent.com/10141928/150661877-d975b35b-3e55-4333-b216-2f9df6ca2584.png`;
export const SONG_CDN_URL = `https://res.cloudinary.com/invitato/video/upload/v1640358887/template-song/LeeHi_-_ONLY_Lyrics_nzuosa.mp4`;

export const IMG_COVID = {
  id: `https://user-images.githubusercontent.com/10141928/152914092-4dfb40e5-9552-49dc-a2d4-72a345f08b06.png`,
  en: `https://user-images.githubusercontent.com/10141928/158618733-55b6141e-dc5a-4a6e-a65e-953cb09127dc.jpg`,
};

export const LOGO_INVITATO = `https://user-images.githubusercontent.com/10141928/150662036-816aabf8-76f9-4342-bc47-3d7892362753.png`;

//Collaboration
export const LOGO_VENDOR_DALANG = `https://ik.imagekit.io/invitatoid/global-assets/logo-partner/partner-dalang_bp5JgCazz.png?ik-sdk-version=javascript-1.4.3&updatedAt=1651639908257`;
export const LOGO_VENDOR_PENTONE_WHITE = `https://ik.imagekit.io/invitatoid/global-assets/logo-partner/partner-pentone-white_4cMUJafwM.png?ik-sdk-version=javascript-1.4.3&updatedAt=1654518552744`;
export const LOGO_VENDOR_PENTONE_BLACK =
  'https://user-images.githubusercontent.com/103095433/193169607-562d8316-835d-4c15-a6b4-2b3c7cd22621.png';
export const LOGO_VENDOR_TIRTOWENING =
  'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-tirtowening_2o6jmuIlN.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708911787';
export const LOGO_VENDOR_SAMAWA =
  'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-samawa_KniSfe28i.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708911529';
export const LOGO_VENDOR_SADEWA =
  'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-sadewa_6J3D8PmsJ.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708911185';
export const LOGO_VENDOR_PRAHASTA =
  'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-prahasta-white_5L4WQTBlc.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708910828';
export const LOGO_VENDOR_FLATTER =
  'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-flatter_SvHO8RoVF.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708910005';
export const LOGO_VENDOR_AKSARA =
  'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-aksara-white_Ofc6Xkib-.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708909659';
export const LOGO_VENDOR_DREAMWORKS =
  'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-dreamworks-white_FFlUUi8Dk.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708910073';
